import React from 'react';
// import sections
import ContactSplit from '../components/sections/ContactSplit';

const Contact = () => {

  return (
    <>
      <ContactSplit invertMobile topDivider imageFill className="illustration-section-02" />
    </>
  );
}

export default Contact;