import React from 'react';
// import sections
import AboutSplit from '../components/sections/AboutSplit';

const About = () => {

  return (
    <>
      <AboutSplit invertMobile topDivider imageFill className="illustration-section-02" />
    </>
  );
}

export default About;