import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AboutSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '誰是我們',
    paragraph: '飯腳們是一個幫助人找「飯腳」的手機應用程式。用戶可選擇隨機配對，又或者在公開群組中邀請其他用戶一同吃飯。有了「吃飯」的主題，用戶不用再「過五關、斬六將」去尋找見面的藉口，吃一頓飯便是最好的藉口！'
  };

  const sectionHeader2 = {
    title: '使命',
    paragraph: '飯腳們的使命是「你永遠不會獨自吃飯!」'
  };

  const sectionHeader3 = {
    title: '願景',
    paragraph: '飯腳們想顛覆傳統遊戲規則，令到整個「配對和約會」的過程變得更快、更容易和更安全。飯腳們希望透過吃飯這個媒介，令到程式成為用家找尋交友對象的首選。飯腳們亦大分關心使用者的體驗，致力提供一個安全和舒適的環境讓用戶可以暢所欲言，放心地結交朋友。'
  };

  const sectionHeader4 = {
    title: '定位',
    paragraph: '飯腳們的主要目標用戶群是大學生，這是因為創辦人留意到很多大學生在課堂與課堂之間都是獨自吃飯，甚為孤獨，而飯腳們正好作為一道橋樑，連繫這一群獨自吃飯的大學生。與此同時，飯腳們亦將年輕一族納為目標用戶群，取其對網上交友之概念更為接納。飯腳們將自己定位為一個以「吃飯」為主題的交友手機應用程式，亦希望吸引一群重視溝通、比較少重視外表的用戶群，並希望藉此能令用戶有更深入的討論和對話。'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content  reveal-from-bottom" />
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/friends-lunch.jpg')}
            alt="friends eating lunch together"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader2} className="center-content  reveal-from-bottom" />
            <SectionHeader data={sectionHeader3} className="center-content  reveal-from-bottom" />
            <SectionHeader data={sectionHeader4} className="center-content  reveal-from-bottom" />
          </div>
        </div>
      </div>
    </section>
  );
}

AboutSplit.propTypes = propTypes;
AboutSplit.defaultProps = defaultProps;

export default AboutSplit;